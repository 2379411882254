<template>
  <div class="complain">
    <div class="ts" @click="openData">
      <img src="@/assets/images/teacherStudio/ts.png" alt="" />
      <span>投诉</span>
    </div>
    <el-dialog title="投诉" :visible.sync="TbVisible" width="852px" @close="onClose" :modal='false'>
      <el-form :model="form" :rules="rules" ref="form">
        <el-form-item label="所属分类：" prop="type">
          <div>
            <!-- <el-checkbox-group
              v-model="selectArr"
              @change="handleCheckedCitiesChange"
            >
              <el-checkbox
                ref="checkBox"
                v-for="(item, index) in list"
                :label="item.id"
                :key="index"
                style="margin-right: 10px; margin-bottom: 15px"
                >{{ item.name }}</el-checkbox
              >
            </el-checkbox-group> -->
            <el-radio-group v-model="form.type">
              <el-radio :label="item.id" v-for="(item, index) in list" :key="index">{{ item.name }}</el-radio>
            </el-radio-group>
          </div>
        </el-form-item>
        <el-form-item label="问题描述：" prop="content">
          <div class="tareas">
            <el-input type="textarea" :autosize="{ minRows: 4 }" placeholder="请输入存在具体问题描述..." v-model="form.content">
            </el-input>
          </div>
        </el-form-item>
        <el-form-item label="" prop="" class="myupload">
          <div class="upImg" label-width="100px">
            <!-- 上传后图片div -->
            <div v-if="imglist.length > 0" class="df">
              <div v-for="(e, i) in imglist" :key="i" class="mr10 avatar">
                <img :src="e" />
                <p class="del" @click="onDelImg(e)">删除</p>
              </div>
            </div>
            <!-- 金山云组件上传图片 -->
            <dc-upload class="avatar-uploader" ref="upload" :dcAutoUpload="true" :show-file-list="false" dcdom="ceshi" :dcConfig="dcConfig"
              @dc-success="dcSuccess" @dc-progress="dcProgressTrue = false" v-if="isUpOk">
              <!-- 没上传div -->
              <div class="jh">
                <i class="el-icon-plus avatar-uploader-icon"></i>
                <p class="upload_txt">上传</p>
              </div>
            </dc-upload>
          </div>
          <p class="fs14 c9">格式支持jpg、jpeg、png，大小不超过xxM。</p>
        </el-form-item>
      </el-form>
      <div class="fcc">
        <div class="df">
          <div @click="TbVisible = false" class="footer_btn mr22">取消</div>
          <div @click="onCommit" class="footer_btn cf sure">确定</div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { Reclamation } from "@/api/teacherStudio";
export default {
  props: ['type', 'id', "is_from"],
  data() {
    return {
      //数组12
      list: [
        {
          id: 1,
          name: "非法",
        },
        {
          id: 2,
          name: "谩骂讽刺",
        },
        {
          id: 3,
          name: "广告",
        },
        {
          id: 4,
          name: "谣言",
        },
        {
          id: 5,
          name: "暴力恐怖",
        },
        {
          id: 6,
          name: "其他",
        },
      ],
      selectArr: [], //选中的
      //图片地址
      cover_url: "",
      isUpOk: false,
      //金山云上传配置
      dcConfig: {
        key: "", // 后台域名对应key
        notify_url: "", // 上传接口地址
      },
      TbVisible: false,
      form: {
        type: [],
        content: "",
        content_image: [],
        model: '',   // 1：直播 2：活动 3：课程 4：教研组
        // 对应的模块id   直播   活动  课程id  教研组ID
        complaint_id: '',
        group_studio_id: '' //1是教研组   2是工作室
      },
      rules: {
        type: [
          { required: true, message: "请选择所属分类", trigger: "change" },
        ],
        content: [
          { required: true, message: "请输入问题描述", trigger: "blur" },
          {
            min: 0,
            max: 500,
            message: "长度在 500 个字符以下",
            trigger: "blur",
          },
        ],
        content_image: [
          { required: true, message: "请上传图片", trigger: "change" },
        ],
      },
      imglist: [],
    };
  },
  created() {
    //初始化金山云
    this._getAddress();
  },
  methods: {
    //单选
    handleCheckedCitiesChange(value) {
      console.log(value, "勾选·1");
    },
    // 删除图片
    onDelImg(e) {
      let index = this.imglist.indexOf(e);
      this.imglist.splice(index, 1);
      this.form.content_image.splice(index, 1);
    },
    //打开选项
    openData() {
      this.TbVisible = !this.TbVisible;
    },
    onClose() {
      this.$refs.form.resetFields();
      this.form = this.$options.data().form;
    },
    // 提交
    onCommit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.form.complaint_id = this.id;
          this.form.model = this.type;
          this.form.group_studio_id = this.is_from; // 1教研组  2工作室
          Reclamation(this.form).then(() => {
            this.$message.success("投诉成功");
            this.TbVisible = false;
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 获取金山云配置
    async _getAddress() {
      let res = await this.$axios.get("/k3sSetting");
      this.dcConfig.key = res.data.data.key;
      this.dcConfig.notify_url = res.data.data.callBack;
      this.isUpOk = true;
    },
    // 上传成功后的回调
    async dcSuccess(data) {
      console.log(data, "data");
      if (!data.upload) return;
      // 得到上传文件的数据list
      let resData = data.upload.CompleteMultipartUploadResult;
      //去掉resData.ETag属性的双引号
      resData.ETag = resData.ETag.replace(/"/g, "");
      let etag = resData.ETag;
      //请求erg获取上传文件返回的etagid
      let res = await this.$axios.get("/etagChangeId", {
        params: {
          etag: etag,
        },
      });
      //把本地文件地址给cover_url变量
      this.imglist.push(resData.Location);
      this.form.content_image.push(res.data.data.id);
      console.log(res, "res数据");
    },
  },
};
</script>

<style lang="less" scoped>
* {
  box-sizing: border-box;
}
.complain {
  .ts {
    padding: 2px 10px;
    color: #ccc;
    font-size: 14px;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 6px 0 6px 0;
    cursor: pointer;
    > img {
      margin-right: 5px;
      vertical-align: middle;
    }
  }
  /deep/ .el-form {
    .el-textarea {
      width: 608px !important;
    }
    .myupload {
      .el-form-item__content {
        padding-left: 91px;
      }
      .upImg {
        display: flex;
        .avatar {
          position: relative;
          width: 134px;
          height: 75px;
          &:hover {
            .del {
              display: block;
            }
          }
          img {
            width: 134px;
            height: 75px;
            margin-right: 10px;
          }
          .del {
            display: none;
            position: absolute;
            left: 0;
            bottom: 0;
            background-color: rgba(0, 0, 0, 0.6);
            line-height: 24px;
            font-size: 14px;
            color: #ea5b24;
            text-align: center;
            cursor: pointer;
            width: 100%;
          }
        }
      }
    }
  }
  .footer_btn {
    width: 148px;
    height: 42px;
    background: #f7f7f7;
    border: 1px solid #ececec;
    border-radius: 6px;
    font-weight: 600;
    line-height: 42px;
    text-align: center;
    &.sure {
      background: #ff8201;
    }
  }
  /deep/ .el-dialog__header {
    border-bottom: 1px solid #ebebeb;
  }
  /deep/ .el-dialog {
    z-index: 5000;
  }
  /deep/ .el-upload {
    box-sizing: border-box;
    display: inline-block;
    width: 134px;
    height: 75px;
    text-align: center;
    background: #f7f7f7;
    border: 1px solid #ececec;
    border-radius: 4px;
    padding-top: 8px;
    position: relative;
    .upload_txt {
      line-height: 1;
    }
    .el-icon-plus {
      font-size: 20px;
    }
    /* 垂直水平居中 */
    .el-progress {
      top: 50% !important;
      left: 50% !important;
      transform: translate(-50%, -50%);
    }
  }
}
</style>